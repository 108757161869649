<template>
  <div class="row app-block mb-4">
    <h1>Estatísticas</h1>
    <div class="col-md-3">
      <Card>
        <template #title>
          Tipos de arquivos (qtd. de arquivos)
        </template>
        <template #content>
          <Chart type="doughnut" :data="filetypeQtd" :options="lightOptions" />
        </template>
      </Card>
    </div>
    
    <div class="col-md-3">
      <Card>
        <template #title>
          Tipos de arquivos (espaço)
        </template>
        <template #content>
          <Chart type="doughnut" :data="filetypeSpc" :options="lightOptions" />
        </template>
      </Card>
    </div>

    <div class="col-md-6">
      <Card>
        <template #title>
          Tipos de arquivos
        </template>
        <template #content>
          <DataTable :value="datatable" responsiveLayout="scroll" sortField="size" :sortOrder="-1">
            <Column field="mimetype" header="Tipo" :sortable="true"></Column>
            <Column field="count" header="Qtd." :sortable="true">
              <template #body="slotProps">
                {{ new Intl.NumberFormat('pt-BR').format(slotProps.data.count) }}
              </template>
            </Column>
            <Column field="size" header="Tamanho" :sortable="true">
              <template #body="slotProps">
                {{ formatFilesize(slotProps.data.size) }}
              </template>
            </Column>
          </DataTable>

          <p class="mt-4">
            <strong>Total de arquivos:</strong> {{ new Intl.NumberFormat('pt-BR').format(files) }}
          </p>
          <p>
            <strong>Total de pastas:</strong> {{ new Intl.NumberFormat('pt-BR').format(folders) }}
          </p>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import Chart from 'primevue/chart';
import Card from 'primevue/card';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import statisticsApi from '../../api/statistics';
import colorHelper from '../../helpers/colors';
import { filesize } from "filesize";

export default {
  components: { Chart, Card, DataTable, Column },
  data() {
    return {
      files: 0,
      folders: 0,
      datatable: [],
      filetypeQtd: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: []
          }
        ]
      },
      filetypeSpc: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: []
          }
        ]
      },
      lightOptions: {
				plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        }
			},
    }
  },
  methods: {
    formatFilesize: filesize,
  },
  async created() {
    const { files, folders, mimes } = await statisticsApi.getStatistics();

    this.datatable = mimes;
    this.files = files;
    this.folders = folders;

    const count = mimes.length;

    this.filetypeQtd.labels = mimes.map((el) => el.mimetype);
    this.filetypeSpc.labels = [...this.filetypeQtd.labels];
    this.filetypeQtd.datasets[0].data = mimes.map((el) => el.count);
    this.filetypeSpc.datasets[0].data = mimes.map((el) => el.size);

    for (let i = 0; i < count; i++) {
      this.filetypeQtd.datasets[0].backgroundColor.push(colorHelper.generateColorRgb());
    }

    this.filetypeSpc.datasets[0].backgroundColor = [...this.filetypeQtd.datasets[0].backgroundColor];
  }
}
</script>
