import axios from 'axios';
import store from '@/store';

export default {
  async getStatistics() {
    const url = `${store.getters.apiEndpoint}/statistics`;
    const { data } = await axios.get(url);

    return data;
  }
}